<template>
  <div class="app-container">
    <article class="article-content">
      <h2 class="article-content__heading article-content__heading--h2">
        Kim jesteśmy?
      </h2>
      <p class="article-content__paragraph">
        <strong>Salvatti EDU</strong> to nowy projekt Pallotyńskiej Fundacji Misyjnej Salvatti.pl,
        jednej z najbardziej rozpoznawanych organizacji pozarządowych niosących pomoc ludziom nękanym przez ubóstwo,
        wojny i klęski żywiołowe w różnych regionach świata.
        Nie chodzimy utartymi ścieżkami – wykorzystujemy nowe innowacyjne metody docierania i angażowania ludzi dobrej woli,
        wdrażamy modele pomocy, które zapewniają trwały efekt. Zapraszamy Cię do wspólnego działania.
      </p>
      <p class="article-content__paragraph">
        W tym projekcie stawiamy na <strong>edukację dzieci</strong> - jeden z najbardziej efektywnych sposobów pomocy,
        ponieważ właśnie dobra edukacja poszerza horyzonty,
        stwarza możliwości rozwoju talentów i buduje potencjał realnej zmiany jakości życia danej społeczności.
        Dobrze wykształcona młodzież w dorosłym życiu będzie potrafiła zadbać o siebie, o swoje wioski,
        a potem o swoje ojczyzny. Dzięki temu  świat będzie się stawał lepszy,
        a przepaść między biednymi a bogatymi nie będzie aż tak głęboka.
        Dołącz do nas, by mieć w tym swój udział.
      </p>
      <img class="article-content__image" :src="getImageSrc('picture_1.jpg')" alt="Edukację dzieci">
      <p class="article-content__paragraph">
        Wspieramy przede wszystkim placówki założone i prowadzone przez misjonarzy, ponieważ będąc na miejscu,
        znają oni doskonale potrzeby lokalnych mieszkańców i są z nimi na dobre i na złe.
        Dodatkowo misjonarze dbają o właściwe, zgodne z przeznaczeniem,
        wykorzystanie otrzymanych środków i dokładają wszelkich starań
        by pomoc obejmowała wszystkie potrzebujące dzieci danej społeczności.
        Obecnie naszą szczególną troską otaczamy placówki edukacyjne na
        Wybrzeżu Kości Słoniowej, w Kamerunie, w Etiopii, w Rwandzie, w Indiach oraz w Kolumbii i Wenezueli.
      </p>
      <p class="article-content__paragraph">
        W bogatej różnorodności kulturowej społeczności i ośrodków rozsianych po całym świecie,
        wspólnym mianownikiem jest charakter pomocy niezbędnej dla efektywnej realizacji celów edukacyjnych.
      </p>
      <p class="article-content__paragraph">
        Nasze działania pomocowe  obejmują dwie kategorie:
      </p>
      <ul class="article-content__list">
        <li class="article-content__list-item">
          <strong>Infrastruktura</strong> czyli fizyczna budowa szkół, przedszkoli i kompletowanie wszelkiego
          niezbędnego wyposażenia umożliwiającego pracę z dziećmi w godnych warunkach,
        </li>
        <li class="article-content__list-item">
          <strong>Utrzymanie dzieci</strong> czyli stała pomoc w finansowaniu edukacji uczniów,
          która pomoże w organizacji niezbędnego zaplecza i zapewnieniu odpowiedniej opieki kadry nauczycielskiej w sytuacji,
          w której wsparcie Fundacji jest niejednokrotnie głównym lub jedynym źródłem finansowania.
        </li>
      </ul>
      <img class="article-content__image" :src="getImageSrc('picture_2.jpg')" alt="Edukację dzieci">
      <p class="article-content__paragraph">
        Dla nas pomoc to branie długookresowej odpowiedzialności za wspieraną szkołę lub przedszkole,
        bo doświadczenie pokazuje, że tylko tak można budować trwałe rezultaty,
        przerywać zaklęty cykl biedy i braku perspektyw oraz dawać nadzieję na lepsze jutro.
        Salvatti EDU to zatem nie tylko domena czy nowy „brand”,
        ale przede wszystkim nasze zobowiązanie do czynienia świata lepszym.
      </p>
    </article>
    <article class="article-content">
      <h2 class="article-content__heading article-content__heading--h2">
        Nasze zasady
      </h2>
      <p class="article-content__paragraph">
        Bardzo ważne jest dla nas skupienie wokół projektu Salvatti EDU jak największej liczby osób,
        które chcą zmieniać świat. W związku z tym sformułowaliśmy zasady,
        którymi kierujemy się w naszej działalności i które, mamy nadzieję,
        pozwolą Ci odnaleźć się w naszej społeczności:
      </p>
      <ul class="article-content__list">
        <li class="article-content__list-item">
          <strong>Trwały efekt</strong> – wspierając edukację, inwestujemy w potencjał ludzki,
          tworzymy bazę rozwoju wspieranych społeczności,
        </li>
        <li class="article-content__list-item">
          <strong>Stałe wsparcie</strong> – nasze działania nie są sporadyczne, okazjonalne, ale stanowią nasze stałe
          zobowiązanie,
          które tworzy zaplecze stabilizacji i trwałej zmiany,
        </li>
        <li class="article-content__list-item">
          <strong>Doświadczenie</strong> - wieloletnie, umiemy profesjonalnie pomagać, wiemy co działa a co nie,
          nie starujemy od zera, ale budujemy procesy na bazie wieloletnich obserwacji i doświadczeń,
        </li>
        <li class="article-content__list-item">
          <strong>Transparentność </strong> - szanujemy pieniądze naszych Darczyńców,
          kontrolujemy cały proces od definiowania celu po wykorzystywanie zebranych środków, raportujemy, rozliczamy
          się,
        </li>
        <li class="article-content__list-item">
          <strong>Relacje</strong> - pomagamy nie tylko pieniędzmi, ale i swoją obecnością dlatego jesteśmy skuteczni,
          tworzymy ramy organizacyjne wolontariatu dla ludzi, którzy chcą coś zrobić dla innych,
        </li>
        <li class="article-content__list-item">
          <strong>Innowacyjność </strong> - szukamy efektywności, staramy się budować skalę,
          jako Fundacja tworzymy efektywne modele biznesowe, inwestujemy w technologię bo chcemy zrobić jak najwięcej
          dobrego.
        </li>
      </ul>
      <img class="article-content__image" :src="getImageSrc('picture_3.jpg')" alt="Edukację dzieci">
    </article>
    <article class="article-content">
      <h2 class="article-content__heading article-content__heading--h2">
        Połączmy siły
      </h2>
      <p class="article-content__paragraph">
        Salvatti EDU to również internetowy portal do organizacji zbiórek na rzecz ośrodków edukacyjnych.
        Zapraszamy do współpracy wszelkie organizacje i placówki oświatowe, które sprawują pieczę nad dziećmi,
        które współfinansują swoją działalność z darowizn i dla których bliskie są zasady naszej Fundacji.
        Prosimy zainicjować kontakt mailowy na
        <a href="mailto:pomoc@salvatti.edu.pl">pomoc@salvatti.edu.pl</a>,
        skontaktujemy się i przedstawimy możliwości wsparcia
        Waszych ośrodków przy wykorzystaniu potencjału niniejszego serwisu.
      </p>
      <img class="article-content__image" :src="getImageSrc('picture_4.jpg')" alt="Edukację dzieci">
    </article>
  </div>
</template>

<script>
export default {
  name: 'AdoptionStatic',
  inject: ['changePageTitle'],
  created() {
    this.changePageTitle('page_adoption_static.title')
  },
  methods: {
    getImageSrc(imageName) {
      return require(`../../../assets/images/static-page-adoption/${imageName}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/grid-mixins";

.article-content {
  @include desktop {
    padding: 0 8em;
  }
}
</style>
